import React from "react";
import { sectionForCategory, sectionForOtherCategories } from "components/common/FooterAccordion";
import T from "types/index";
import { pathologiesGrandMotherSlug } from "templates/Pathologies/utils/slugs";
import { get } from "lodash";
import useAirtableCategories from 'hooks/useAirtableCategories';
import SurFooter from "templates/Pathologies/common/SurFooter";

type Props = {
  breadcrumbLinks: T.Standard.BreadcrumbLink[];
  className?: string;
  pathologyRecord: T.Charles.PathologyRecord
};

const PathoSurFooter = ({ breadcrumbLinks, pathologyRecord, className }: Props) => {
  const categoriesRecords: T.Charles.CategoryRecord[] = useAirtableCategories()
  const currentCategoryNodeId = get(pathologyRecord, 'data.category[0].id')
  const otherCategories = categoriesRecords.filter(
    (categoryNode: T.Charles.CategoryRecord) =>
      currentCategoryNodeId !== categoryNode.id
  );
  const pathoCategoryRecord: T.Charles.CategoryRecord = (categoriesRecords.find(
    (categoryNode: T.Charles.CategoryRecord) =>
      currentCategoryNodeId === categoryNode.id
  ) as T.Charles.CategoryRecord);

  const accordionLinks: T.Standard.FooterAccordionSection[] = [
    sectionForCategory(pathoCategoryRecord, [pathologyRecord.id]),
    sectionForOtherCategories(otherCategories)
  ]
  return (
    <SurFooter
      breadcrumbLinks={breadcrumbLinks}
      footerContent={{
        title: "Toutes nos pathologies",
        titleLink: pathologiesGrandMotherSlug(),
        sections: accordionLinks,
      }}
      className={className}
    />
  );
};

export default PathoSurFooter;
